'use client';

import React from 'react';
import Container from '@src/components/Shared/Container';
import SectionHeader from '@src/components/Shared/SectionHeader';
import NextImage from '@src/components/Shared/NextImage';
import { MoveHealthAppPropTypes } from './ProgressOutcomes.types';
import ParagraphSeparator from '@src/components/Shared/ParagraphSeparator';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import RenderButtons from '@src/components/Shared/RenderButtons';
import useTileFadeIn from '@src/hooks/useTileFadeIn';
const ProgressOutcomes: React.FC<MoveHealthAppPropTypes> = ({
  data
}) => {
  const {
    figureRef,
    addDataTiles
  } = useTileFadeIn();
  React.useEffect(() => {
    if (figureRef.current) {
      const childElements = figureRef.current.querySelectorAll('.data-tile');
      childElements.forEach(el => addDataTiles(el as HTMLElement));
    }
  }, [figureRef, addDataTiles]);
  return <Container className={`flex flex-col gap-[4.2rem] overflow-y-hidden lg:flex lg:flex-row lg:items-center lg:gap-[6.4rem]`} data-sentry-element="Container" data-sentry-component="ProgressOutcomes" data-sentry-source-file="ProgressOutcomes.tsx">
      <div className={`order-1 md:order-[0] lg:relative lg:z-[20] lg:w-[50%] lg:self-center`}>
        <SectionHeader className={`lg:relative lg:z-[5] lg:py-[4rem]`} preTitleClassName='mb-[1.6rem] font-[400]' title={data?.title} titleClassName={`text-[2.8rem] mb-[1.8rem] lg:mb-[3.2rem] lg:text-[4.8rem] 2xl:text-[6.1rem] lg:leading-[5rem] 2xl:leading-[5.2rem] lg:tracking-[-0.096rem]`} childrenClassName='relative' align='left' data-sentry-element="SectionHeader" data-sentry-source-file="ProgressOutcomes.tsx">
          <ParagraphSeparator data-sentry-element="ParagraphSeparator" data-sentry-source-file="ProgressOutcomes.tsx">
            <RenderBodyCopy className='max-w-[47rem]' bodyCopy={data?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="ProgressOutcomes.tsx" />
          </ParagraphSeparator>
          <RenderButtons items={data?.buttonCollection?.items} data-sentry-element="RenderButtons" data-sentry-source-file="ProgressOutcomes.tsx" />
        </SectionHeader>
      </div>
      <NextImage imgRef={figureRef} className={`opacisty-[0] rounded-[1.5rem] lg:mb-[unset] lg:w-[50%]`} src={data?.mediaPrimary?.url} alt={data?.mediaPrimary?.description} aspectRatio={data?.mediaPrimary?.width / data?.mediaPrimary?.height} width={data?.mediaPrimary?.width} objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="ProgressOutcomes.tsx">
        <NextImage src='https://images.ctfassets.net/98s79sqwuajy/4ZJ91LhuzOK011pZ3Q1quP/75dafc9d2ccb903f67dc8a45fc43d24e/ProgressOutcomes-tile1.svg' alt='ProgressOutcomes tile 1' className='data-tile left-[10%] top-[24%] w-[40%] lg:w-[16rem] 2xl:top-[29%] 2xl:w-[27%]' data-sentry-element="NextImage" data-sentry-source-file="ProgressOutcomes.tsx" />

        <NextImage src='https://images.ctfassets.net/98s79sqwuajy/5fR79eeQw5hMxUrmcQO3RS/1e285e66a11221ca8e812bcda19f2a90/ProgressOutcomes-tile2.svg' alt='ProgressOutcomes tile 2' className='data-tile left-[50%] top-[-1%] w-[40%] lg:w-[20rem] xl:left-[60%] 2xl:left-[65%] 2xl:top-[5%] 2xl:w-[30%]' data-sentry-element="NextImage" data-sentry-source-file="ProgressOutcomes.tsx" />

        <NextImage src='https://images.ctfassets.net/98s79sqwuajy/5MBpTL7AeUbjzHSi7XMkOc/cd5c233505bede23b1d23d280415d948/ProgressOutcomes-tile3.svg' alt='ProgressOutcomes tile 3' className='data-tile left-[42%] top-[4%] hidden w-[40%] lg:block lg:w-[20rem] xl:top-[17%] 2xl:top-[23%] 2xl:w-[30%]' data-sentry-element="NextImage" data-sentry-source-file="ProgressOutcomes.tsx" />
      </NextImage>
    </Container>;
};
export default ProgressOutcomes;