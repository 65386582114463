'use client';

import React from 'react';
import NextImage from '@src/components/Shared/NextImage';
import Container from '@src/components/Shared/Container';
import { TilesGridPropTypes } from './TilesGrid.types';
import useAnimateGridTile from '@src/hooks/useAnimateGridTile';
const TilesGrid: React.FC<TilesGridPropTypes> = ({}) => {
  const {
    containerRef,
    oneRef,
    twoRef,
    threeRef,
    fourRef,
    fiveRef,
    sixRef,
    sevenRef,
    eightRef,
    nineRef,
    tenRef,
    elevenRef,
    twelveRef,
    thirteenRef,
    fourteenRef
  } = useAnimateGridTile();
  return <Container className='!z-[-10] hidden pb-[2rem] md:block 2xl:pb-[5rem]' background="bg-[url('https://images.ctfassets.net/98s79sqwuajy/5BBQjgp1MbUgnPDKwKMzym/e5ae68857cb395a2a44979f463773e99/gird-background.jpg')] bg-cover bg-[75%_40%] bg-no-repeat" containerRef={containerRef} data-sentry-element="Container" data-sentry-component="TilesGrid" data-sentry-source-file="TilesGrid.tsx">
      <div className='my-auto !mb-[0] mt-[5%] grid grid-cols-[2.6fr,2.5fr,2.5fr,2.4fr] gap-[2rem]'>
        <div className='col-span-1'>
          <NextImage imgRef={sevenRef} src='https://images.ctfassets.net/98s79sqwuajy/1OkLkGIMCaYhdY8yuH3daM/36c7599a022a3d3deba144b250ec7bdc/movehealth-welcome-john.svg' alt='movehealth welcome John' objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
        </div>
        <div className='col-span-2 grid grid-cols-[2.5fr,2.7fr,2fr,2.8fr] gap-[2rem]'>
          <NextImage imgRef={twoRef} src='https://images.ctfassets.net/98s79sqwuajy/2ixW8jZ7ZuzzAHiSXAFxcv/ab70068b34a0a3bd3d39d69de8456055/average-completion.svg' alt='80% average completion' objectFit='contain' className='col-span-2 row-span-2 opacity-0' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
          <NextImage imgRef={fourRef} src='https://images.ctfassets.net/98s79sqwuajy/3Dzfz9iQxOdUn10KFKlWm5/dfdcd0c99d9361889fb7199cd377ee03/knee-injury.svg' alt='Knee injury & Osteoarthritis' className='col-span-2 row-span-1 opacity-0' objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
          <NextImage imgRef={twelveRef} src='https://images.ctfassets.net/98s79sqwuajy/5yLFUXERBsqpQpJhTtRhab/6c844281bc01c0ed8650fa99f8825fe0/knee-extension.svg' alt='Knee extension seated' className='col-span-2 row-span-1 opacity-0' objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
          <NextImage imgRef={threeRef} src='https://images.ctfassets.net/98s79sqwuajy/pAoIiYVM50WD8HzVzAMpg/5fba9ddb6a8c0d1ad2bea412f95f3dcf/pain.svg' alt='Pain rate' className='col-span-1 row-span-2 opacity-0' objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
          <NextImage imgRef={oneRef} src='https://images.ctfassets.net/98s79sqwuajy/t2bPd1vHMrjxdHqsPlPXP/908e4d4cd73552cb9f6d36230cb4d1d3/mh-logo.svg' alt='Movehealth logo orange' className='col-span-2 row-span-1 opacity-[0]' objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
          <NextImage imgRef={sixRef} src='https://images.ctfassets.net/98s79sqwuajy/1chFwwd7BkM138uxTsR7Bv/7849f1dea2d6580f07da063ae0a71e00/circular-exercise.svg' alt='circulation exercise' className='col-span-1 row-span-2 opacity-0' objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
          <NextImage imgRef={tenRef} src='https://images.ctfassets.net/98s79sqwuajy/6zL3YrLWb1AqvTTm3EqVkO/7b4d59e4b3c6e1958e4e3bbc29db9e8c/bugarian.svg' alt='bulgarian split squat' className='col-span-2 row-span-1 opacity-0' objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
          <NextImage imgRef={fiveRef} src='https://images.ctfassets.net/98s79sqwuajy/4jDG7GntrpyNwFhargQwkd/4edf2ccd747d2b8fc3336d5e54a715ce/pain-score-8.svg' alt='Pain score 8' className='col-span-2 opacity-0' objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
          <NextImage imgRef={elevenRef} src='https://images.ctfassets.net/98s79sqwuajy/71y6FQTgptp3fIB1dzZKwz/cf4e9a145229b4c78e0c4da6607929b9/score-91-100.svg' alt='higher score 91/100' className='col-span-2 opacity-0' objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
        </div>
        <div className='col-span-1 grid grid-cols-3 gap-[2rem]'>
          <NextImage imgRef={eightRef} src='https://images.ctfassets.net/98s79sqwuajy/6NfP8lYZa9X2KJJA7SAv9j/ab91990f051846e265e8fc39bdb76c23/session-pause.svg' alt='session-pause-superset-a' className='col-span-3 opacity-0' objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
          <NextImage imgRef={nineRef} src='https://images.ctfassets.net/98s79sqwuajy/2maRIuPJZLcy1zxTF0vsgH/e97ec9537818e4d7b4f6200372de01c7/single-leg-hip-hinge.svg' alt='single-leg-hip-hinge' className='col-span-2 opacity-0' objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
          <NextImage imgRef={fourteenRef} src='https://images.ctfassets.net/98s79sqwuajy/1KM73ZfiOdIMyDy9q7KHKG/eaa9bfa4481fbcf4634d64532e606f3c/tick-complete.svg' alt='tick-complete' className='col-span-1 opacity-0' objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
          <NextImage imgRef={thirteenRef} src='https://images.ctfassets.net/98s79sqwuajy/4c7LOt8gY90Lh2uO54WEWJ/3428b51eb14553b63dbb5f017c049f06/knee-bend.svg' alt='knee-bend' className='col-span-3 opacity-0' objectFit='contain' data-sentry-element="NextImage" data-sentry-source-file="TilesGrid.tsx" />
        </div>
      </div>
    </Container>;
};
export default TilesGrid;