'use client';

import Container from '@src/components/Shared/Container';
import NextImage from '@src/components/Shared/NextImage';
import styles from './dataTilesTwo.module.css';
import useTileFadeIn from '@src/hooks/useTileFadeIn';
export default function DataTilesTwo({
  attr,
  imageSrc,
  alt,
  sectionClass,
  boxClassName,
  imageClassName,
  objectFit = 'cover'
}: any) {
  const {
    figureRef,
    addDataTiles
  } = useTileFadeIn();
  return <Container attr={attr} className={sectionClass} data-sentry-element="Container" data-sentry-component="DataTilesTwo" data-sentry-source-file="dataTilesTwo.tsx">
      <div className={`${styles.dataTilesWrapper}`}>
        <div className='relative mb-[2rem]'>
          <NextImage imgRef={figureRef} className={`${imageClassName} rounded-[1.5rem]`} src={imageSrc} alt={alt} aspectRatio={2496 / 1552} priority={true} width={2496} objectFit={objectFit} data-sentry-element="NextImage" data-sentry-source-file="dataTilesTwo.tsx"></NextImage>

          <div className={`${styles?.dataTilesModal}`}>
            <div ref={el => addDataTiles(el)} className={`${styles?.modalInput} left-[47%] top-[44%] block w-[25rem] md:grid md:h-auto lg:w-[18rem] 2xl:w-[25rem] 3xl:w-[30rem]`}>
              <div className={`${styles?.modalContent} ${boxClassName} px-[2rem] py-[2.3rem]`}>
                <div>
                  <NextImage src='https://images.ctfassets.net/98s79sqwuajy/2CJwuqE8pZnHPf5aMzORHp/5f41505e7b6334cefe04c5b3af9a560b/dtile2.svg' alt='icon' width={183} priority={true} aspectRatio={183 / 91} data-sentry-element="NextImage" data-sentry-source-file="dataTilesTwo.tsx"></NextImage>
                </div>
              </div>
            </div>
            <div ref={el => addDataTiles(el)} className={`${styles?.modalInput} left-[75%] top-[65%] block w-[25rem] md:grid md:h-auto lg:w-[18rem] 2xl:w-[25rem] 3xl:w-[30rem]`}>
              <div className={`${styles?.modalContent} ${boxClassName} px-[2rem] py-[2.3rem]`}>
                <div>
                  <NextImage src='https://images.ctfassets.net/98s79sqwuajy/7oZSQk4zUkmFPNoHYcXP0f/bd11448fcd31b1bb391de221bf50ac20/dtile1.svg' alt='icon' width={174} priority={true} aspectRatio={174 / 117} data-sentry-element="NextImage" data-sentry-source-file="dataTilesTwo.tsx"></NextImage>
                </div>
              </div>
            </div>
            <div ref={el => addDataTiles(el)} className={`${styles?.modalInput} left-[46%] top-[7%] block w-[25rem] md:grid md:h-auto lg:w-[18rem] 2xl:w-[25rem] 3xl:w-[30rem]`}>
              <div className={`${styles?.modalContent} ${boxClassName} px-[2rem] py-[2.3rem]`}>
                <div>
                  <NextImage src='https://images.ctfassets.net/98s79sqwuajy/oPkmfKjVdrKAQIC9Lib3y/4d72c6c0db459396903e8f18ae37ffc8/dtile3.svg' alt='icon' width={173} priority={true} aspectRatio={173 / 87} data-sentry-element="NextImage" data-sentry-source-file="dataTilesTwo.tsx"></NextImage>
                </div>
              </div>
            </div>
            <div ref={el => addDataTiles(el)} className={`${styles?.modalInput} left-[78%] top-[8%] block w-[25rem] md:grid  md:h-auto lg:w-[18rem] 2xl:w-[25rem] 3xl:w-[30rem]`}>
              <div className={`${styles?.modalContent} ${boxClassName} px-[2rem] py-[2.3rem]`}>
                <div>
                  <NextImage src='https://images.ctfassets.net/98s79sqwuajy/6UajM4fShcTUqz8iiSAh1A/7cb0d236c2b9e2899b59d46435122702/dtile4.svg' alt='icon' width={195} priority={true} aspectRatio={195 / 90} data-sentry-element="NextImage" data-sentry-source-file="dataTilesTwo.tsx"></NextImage>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>;
}