'use client';

import React, { useRef, useState, useEffect } from 'react';
import Container from '@src/components/Shared/Container';
import SectionHeader from '@src/components/Shared/SectionHeader';
import useGsapLoaded from '@src/hooks/useGsapLoaded';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import { FaqTypes } from './Faq.types';
import ParagraphSeparator from '@src/components/Shared/ParagraphSeparator';
import ArrowForward from '@src/components/Icons/ArrowForward';
const Faq: React.FC<FaqTypes> = ({
  faq,
  id
}) => {
  // Set the first question (index 0) as active by default
  const [activeQuestion, setActiveQuestion] = useState<number | null>(0);
  const [heights, setHeights] = useState<Record<number, number>>({});
  const answerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const isGsapLoaded = useGsapLoaded([]);
  useEffect(() => {
    if (isGsapLoaded) {
      const newHeights: Record<number, number> = {};
      answerRefs.current.forEach((el, index) => {
        if (el) {
          newHeights[index] = el.scrollHeight;
          window.gsap.set(el, {
            height: activeQuestion === index ? `${el.scrollHeight}px` : '0',
            autoAlpha: activeQuestion === index ? 1 : 0
          });
        }
      });
      setHeights(newHeights);

      // Animate the first question open after heights are set
      if (activeQuestion !== null && answerRefs.current[activeQuestion]) {
        window.gsap.to(answerRefs.current[activeQuestion], {
          height: `${newHeights[activeQuestion]}px`,
          autoAlpha: 1,
          duration: 0.5,
          ease: 'power1.out'
        });
      }
    }
  }, [activeQuestion, isGsapLoaded]);
  const handleQuestionClick = (index: number) => {
    if (isGsapLoaded) {
      if (activeQuestion === index) {
        window.gsap.to(answerRefs.current[index], {
          height: '0',
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power1.out'
        });
        setActiveQuestion(null);
      } else {
        // Close the currently active question
        if (activeQuestion !== null) {
          window.gsap.to(answerRefs.current[activeQuestion], {
            height: '0',
            autoAlpha: 0,
            duration: 0.5,
            ease: 'power1.out'
          });
        }

        // Open the new question
        window.gsap.to(answerRefs.current[index], {
          height: `${heights[index]}px`,
          autoAlpha: 1,
          duration: 0.5,
          ease: 'power1.out'
        });
        setActiveQuestion(index);
      }
    }
  };
  return <Container attr='faq-component' className='py-20 mx-auto flex flex-col' id={id} data-sentry-element="Container" data-sentry-component="Faq" data-sentry-source-file="Faq.tsx">
      <div className=' mx-auto md:w-[80%] 2xl:w-[70%] 3xl:w-[60%]'>
        <SectionHeader className={`lg:relative lg:z-[5] lg:py-[4rem]`} preTitleClassName='mb-[1.6rem] font-[400]' title={faq?.title} pretitle={faq?.preTitle} titleClassName={`text-[2.8rem] mb-[2.4rem] lg:mb-[3.2rem] lg:text-[5.4rem] 2xl:text-[6.1rem] lg:leading-[5.2rem] lg:tracking-[-0.096rem] md:text-center`} childrenClassName='relative'
      // align='center'
      data-sentry-element="SectionHeader" data-sentry-source-file="Faq.tsx" />
        <div className='flex w-full flex-col gap-[1rem]'>
          {faq?.topicCollection?.items?.map((question: any, index) => <div key={index} className={`overflow-hidden rounded-[2rem] py-[2rem] ${activeQuestion === index ? ' bg-background-grey-light' : ' hover:bg-background-grey-light'}`}>
              <button className={`align-left pb-0 flex w-full justify-between px-[2rem] ${activeQuestion === index ? 'pb-[1.5rem]' : 'pb-[0]'}`} onClick={() => handleQuestionClick(index)}>
                <div className='flex w-full items-start justify-between gap-[1rem] text-left'>
                  <h2 className=' text-[2rem] font-[500] xl:text-[2.4rem]'>{question?.title}</h2>
                  <div className='mt-[0.2rem] w-[1rem] xl:w-[1.5rem]'>
                    <ArrowForward className={`w-[1rem] ${activeQuestion === index ? 'rotate-[270deg]' : 'rotate-90'} text-body-copy--dark xl:w-[1.5rem]`} />
                  </div>
                </div>

                <div className={'w-6 h-10 pr-12 m-0 relative flex cursor-pointer items-center justify-center transition-all duration-300 focus:outline-none'}>
                  <span className='top-4 left-3 w-4 absolute h-[2px] bg-primary' />
                  {activeQuestion !== index && <span className='top-4 left-3 w-4 absolute h-[2px] rotate-90 transform bg-primary' />}
                </div>
              </button>
              <div ref={el => {
            answerRefs.current[index] = el;
          }} className='overflow-hidden px-[2rem] transition-all ' style={{
            height: '0',
            visibility: 'hidden'
          }}>
                <ParagraphSeparator className='[&>*:not(:last-child)]:!mb-[1rem] [&>*:not(:last-child)]:lg:!mb-[2rem]'>
                  <RenderBodyCopy className='!text-[1.8rem] !leading-[2.4rem] lg:!text-[2rem] lg:!leading-[3rem] [&>*]:!text-[1.8rem] [&>*]:!leading-[2.4rem] [&>*]:lg:!text-[2rem] [&>*]:lg:!leading-[3rem]' bodyCopy={question?.bodyCopy?.json?.content} />
                </ParagraphSeparator>
              </div>
            </div>)}
        </div>
      </div>
    </Container>;
};
export default Faq;